import React from 'react';
import classNames from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { getTPASettignsLiveSiteEditingDeprecated } from '@wix/communities-blog-client-common';
import CreatePostButton from '../../../feed-page/components/create-post-button';
import { importPrivateProfileActions } from '../../containers/private-profile-actions';
import preventClickInDemoMode from '../../hoc/prevent-click-in-demo-mode';
import useAuth from '../../hooks/use-auth';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import usePermissions from '../../hooks/use-permissions';
import { getRoute } from '../../router/router-selectors';
import { isCreatePostButtonVisible } from '../../selectors/blog-menu-button-selectors';
import { isInPostEditor } from '../../services/detect-route';
import Button from '../button';
import DropdownButton from '../dropdown-button';
import { useActions, useSelector } from '../runtime-context';
import UserAvatarImage from '../user-avatar-image';
import styles from './header-widget.scss';

const ProtectedButton = preventClickInDemoMode(Button);

type Props = {
  searchInput?: React.ReactNode;
};

const HeaderWidget: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isEditor } = useEnvironment();
  const { searchInput } = props;
  const actions = useActions();
  const { currentUser, isAuthenticated } = useAuth();
  const { canSee } = usePermissions();
  const isLiveSiteEditorDisabled = useSelector(getTPASettignsLiveSiteEditingDeprecated);
  const showLogin = useSelector((state) => state.headerWidget.showLogin);

  const isInEditor = useSelector((state) => isInPostEditor(getRoute(state)));
  const isCreatePostBtnVisible = useSelector((state) => isCreatePostButtonVisible(state, canSee));

  const showCreatePostButton = !isLiveSiteEditorDisabled && !isInEditor && isCreatePostBtnVisible;
  const { showLoginButton } = useBlogMenuSettings();

  const renderSearchInput = () => (
    <div className={styles.searchInput} data-hook="search-input">
      {searchInput}
    </div>
  );

  if (
    (!isAuthenticated && !isLiveSiteEditorDisabled) ||
    (isEditor && showLogin && !isLiveSiteEditorDisabled)
  ) {
    return (
      <div className={styles.container}>
        {renderSearchInput()}
        {showLoginButton ? (
          <ProtectedButton
            className={styles.loginButton}
            isSecondary
            onClick={() => actions.requestLogin()}
            data-hook="login-button"
          >
            {t('header.sign-up-button')}
          </ProtectedButton>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        {renderSearchInput()}
        {!isLiveSiteEditorDisabled && showLoginButton && (
          <div className={styles.profile}>
            <DropdownButton
              actionsContainerClassName={styles.profileActions}
              position={DropdownButton.POSITION_RIGHT}
              icon={
                <button
                  className={classNames(styles.avatarImage)}
                  aria-label={t('header.profile')}
                  data-hook="avatar-image"
                >
                  <UserAvatarImage type="widget" user={currentUser} />
                </button>
              }
            >
              {async () => {
                const PrivateProfileActions = await importPrivateProfileActions();
                return <PrivateProfileActions user={currentUser} />;
              }}
            </DropdownButton>
          </div>
        )}
        {showCreatePostButton && (
          <div className={styles.createButtonContainer}>
            <CreatePostButton
              hidePostfix
              className={styles.createPostButton}
              wrapperClassName={styles.createPostButtonWrapper}
            />
          </div>
        )}
      </div>
    );
  }
};

export default HeaderWidget;
