import { createStylesParam, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';
import {
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  RelatedPostsLabel,
  TagAlignment,
  TagLayout,
} from '@wix/communities-blog-client-common';

// #region Display - Checkboxes

export const isMobileDisplaySettingsEnabled = createStylesParam(
  'blog-mobile-isMobileDisplaySettingsEnabled',
  { type: StyleParamType.Boolean, getDefaultValue: () => false },
);

export const showAuthorName = createStylesParam('blog-isAuthorNameEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoType = createStylesParam('blog-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});
/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoTypeMobile = createStylesParam('blog-mobile-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

export const showAuthorPicture = createStylesParam('blog-isAuthorPictureEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({ getStyleParamValue }) => {
    return getStyleParamValue(showLegacyAuthorInfoType) === AUTHOR_INFO_TYPE_NAME_PICTURE;
  },
});

export const showAuthorNameMobile = createStylesParam('blog-mobile-isAuthorNameEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showAuthorPictureMobile = createStylesParam('blog-mobile-isAuthorPictureEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({ getStyleParamValue }) => {
    return getStyleParamValue(showLegacyAuthorInfoTypeMobile) === AUTHOR_INFO_TYPE_NAME_PICTURE;
  },
});

export const showPostPublishDate = createStylesParam('blog-isPostPublishDateEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostPublishDateMobile = createStylesParam('blog-mobile-isPostPublishDateEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showReadingTime = createStylesParam('blog-isReadingTimeEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showReadingTimeMobile = createStylesParam('blog-mobile-isReadingTimeEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostTitle = createStylesParam('blog-isPostTitleEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostTitleMobile = createStylesParam('blog-mobile-isPostTitleEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostUpdatedDate = createStylesParam('blog-isPostUpdatedDateEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostUpdatedDateMobile = createStylesParam('blog-mobile-isPostUpdatedDateEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showCategoryLabel = createStylesParam('blog-isCategoryLabelsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showCategoryLabelMobile = createStylesParam('blog-mobile-isCategoryLabelsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showTags = createStylesParam('blog-isTagsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showTagsMobile = createStylesParam('blog-mobile-isTagsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showMoreOptionsMenu = createStylesParam('blog-isMoreOptionsMenuEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showMoreOptionsMenuMobile = createStylesParam('blog-mobile-isMoreOptionsMenuEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showCommentCount = createStylesParam('blog-isCommentCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showCommentCountMobile = createStylesParam('blog-mobile-isCommentCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showViewCount = createStylesParam('blog-isViewCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showViewCountMobile = createStylesParam('blog-mobile-isViewCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showLikeCount = createStylesParam('blog-isLikeCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showLikeCountMobile = createStylesParam('blog-mobile-isLikeCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostRating = createStylesParam('blog-isPostRatingEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostRatingMobile = createStylesParam('blog-mobile-isPostRatingEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

// #region Display - Menu

/** Classic only */
export const showMenu = createStylesParam('blogMenu-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** Classic only */
export const showMenuMobile = createStylesParam('blogMenu-mobile-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** Studio: should behave same as `showMenu`. Classic: Active when "Display / Show Blog menu" is enabled*/
export const showMenuCategoryLabels = createStylesParam('blogMenu-isCategoryLabelsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** Studio: should behave same as `showMenu`. Classic: Active when "Display / Show Blog menu" is enabled */
export const showMenuCategoryLabelsMobile = createStylesParam(
  'blogMenu-mobile-isCategoryLabelsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

/** Classic only  */
export const showMenuLoginButton = createStylesParam('widget-isLoginButtonEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** Classic only  */
export const showMenuLoginButtonMobile = createStylesParam('blogMenu-mobile-isLoginButtonEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

/** @deprecated Legacy, Classic only  */
export const showMenuSearch = createStylesParam('blogMenu-isSearchEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

/** @deprecated Legacy, Classic only  */
export const showMenuSearchMobile = createStylesParam('blogMenu-mobile-isSearchEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
// #endregion

// #region Display - Share

export const showShare = createStylesParam('socialSharing-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareMobile = createStylesParam('socialSharing-mobile-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showShareIconFacebook = createStylesParam('socialSharing-facebook', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconFacebookMobile = createStylesParam('socialSharing-mobile-facebook', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconX = createStylesParam('socialSharing-twitter', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconXMobile = createStylesParam('socialSharing-mobile-twitter', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconLinkedIn = createStylesParam('socialSharing-linked-in', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconLinkedInMobile = createStylesParam('socialSharing-mobile-linked-in', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconLink = createStylesParam('socialSharing-link', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconLinkMobile = createStylesParam('socialSharing-mobile-link', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showShareIconPrint = createStylesParam('socialSharing-print', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const showSharePluginPinterest = createStylesParam('pinterest-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const showSharePluginX = createStylesParam('twitter-isEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});
// #endregion

// #region Display - Related posts

/** @deprecated Precursor to "blog-isRelatedPostsEnabled", should behave the same */
const showLegacyRecentPosts = createStylesParam('blog-isRecentPostsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showRelatedPosts = createStylesParam('blog-isRelatedPostsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({ getStyleParamValue }) => getStyleParamValue(showLegacyRecentPosts) ?? true,
});
export const showRelatedPostsMobile = createStylesParam('blog-mobile-isRelatedPostsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showRelatedPostsLabel = createStylesParam('blog-isRelatedPostsLabelEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showRelatedPostsLabelMobile = createStylesParam(
  'blog-mobile-isRelatedPostsLabelEnabled',
  { type: StyleParamType.Boolean, getDefaultValue: () => true },
);

export const showRelatedPostsLabelType = createStylesParam('relatedPostsLabel', {
  type: StyleParamType.Number,
  getDefaultValue: () => RelatedPostsLabel.Recent,
});
export const showRelatedPostsLabelTypeMobile = createStylesParam('mobile-relatedPostsLabel', {
  type: StyleParamType.Number,
  getDefaultValue: () => RelatedPostsLabel.Recent,
});
export const showRelatedPostsSeeAllLink = createStylesParam('blog-isSeeAllPostsLinkEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});
export const showRelatedPostsSeeAllLinkMobile = createStylesParam(
  'blog-mobile-isSeeAllPostsLinkEnabled',
  { type: StyleParamType.Boolean, getDefaultValue: () => true },
);

// #endregion

// #region Design - Navigation

export const navigationTextFont = createStylesParam('navigation-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});
export const navigationTextColor = createStylesParam('navigation-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const navigationTextActiveColor = createStylesParam('navigation-textColorActive', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

export const navigationBackgroundColor = createStylesParam('navigation-backgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1', 0),
});

// #endregion

// #region Design - Text style and color

export const textParagraphFont = createStylesParam('post-pageFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Body-M', {
      size: isMobile ? getStyleParamValue(textParagraphFontSizeMobile) : 18,
    })({ textPresets }),
});
const textParagraphFontSizeMobile = createStylesParam('post-mobile-pageFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 16,
});
export const textParagraphColor = createStylesParam('post-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textQuoteFont = createStylesParam('post-quotesFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Body-L', {
      size: isMobile ? getStyleParamValue(textQuoteFontSizeMobile) : 24,
    })({ textPresets }),
});
const textQuoteFontSizeMobile = createStylesParam('post-mobile-quotesFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 20,
});
export const textQuoteColor = createStylesParam('post-quotesColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textLinksAndHashtagsColor = createStylesParam('blog-linkHashtagColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});
export const textH1Font = createStylesParam('post-titleFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Title', {
      size: isMobile ? getStyleParamValue(textH1FontSizeMobile) : 40,
    })({ textPresets }),
});
const textH1FontSizeMobile = createStylesParam('post-mobile-titleFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 26,
});
export const textH1Color = createStylesParam('post-titleColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textH2Font = createStylesParam('post-headerTwoFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Page-title', {
      size: isMobile ? getStyleParamValue(textH2FontSizeMobile) : 28,
    })({ textPresets }),
});
const textH2FontSizeMobile = createStylesParam('post-mobile-headerTwoFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 24,
});
export const textH2Color = createStylesParam('post-headerTwoColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textH3Font = createStylesParam('post-headerThreeFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Heading-XL', {
      size: isMobile ? getStyleParamValue(textH3FontSizeMobile) : 22,
    })({ textPresets }),
});
const textH3FontSizeMobile = createStylesParam('post-mobile-headerThreeFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 20,
});
export const textH3Color = createStylesParam('post-headerThreeColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textH4Font = createStylesParam('post-headerFourFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Heading-L', {
      size: isMobile ? getStyleParamValue(textH4FontSizeMobile) : 20,
    })({ textPresets }),
});
const textH4FontSizeMobile = createStylesParam('post-mobile-headerFourFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 18,
});
export const textH4Color = createStylesParam('post-headerFourColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textH5Font = createStylesParam('post-headerFiveFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Heading-M', {
      size: isMobile ? getStyleParamValue(textH5FontSizeMobile) : 18,
    })({ textPresets }),
});
const textH5FontSizeMobile = createStylesParam('post-mobile-headerFiveFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 16,
});
export const textH5Color = createStylesParam('post-headerFiveColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textH6Font = createStylesParam('post-headerSixFont', {
  type: StyleParamType.Font,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile, textPresets, getStyleParamValue }) =>
    wixFontParam('Heading-S', {
      size: isMobile ? getStyleParamValue(textH6FontSizeMobile) : 16,
    })({ textPresets }),
});
const textH6FontSizeMobile = createStylesParam('post-mobile-headerSixFontSize', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: () => 14,
});
export const textH6Color = createStylesParam('post-headerSixColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
// #endregion

// #region Design - Ratings
// Filled star opacity & color + Unfilled star opacity & color + Text font and color
export const ratingsTextFont = createStylesParam('post-ratingFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-S', { size: 14 }),
});
export const ratingsTextColor = createStylesParam('post-ratingFontColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const ratingsStarFilledColor = createStylesParam('post-ratingFilledStarColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});
export const ratingsStarEmptyColor = createStylesParam('post-ratingUnfilledStarColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-3'),
});

// #endregion

// #region Design - Tags
export const showTagsLabel = createStylesParam('post-isTagLabelEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});
export const tagLayout = createStylesParam('tagLayout', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagLayout.Button,
});
// #endregion

// #region Design - Tags - Button
export const buttonTagAlignment = createStylesParam('tags-buttonTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});

export const buttonTagTextFont = createStylesParam('tags-buttonTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-S', { size: 14 }),
});

export const buttonTagTextColor = createStylesParam('tags-buttonTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const buttonTagTextHoverColor = createStylesParam('tags-buttonTag-textHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const buttonTagBackgroundColor = createStylesParam('tags-buttonTag-backgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1', 1),
});
export const buttonTagBackgroundHoverColor = createStylesParam(
  'tags-buttonTag-backgroundHoverColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
);

export const buttonTagBorderColor = createStylesParam('tags-buttonTag-borderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5', 0.2),
});

export const buttonTagBorderHoverColor = createStylesParam('tags-buttonTag-borderHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5', 0.6),
});
export const buttonTagBorderWidth = createStylesParam('tags-buttonTag-borderWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});
export const buttonTagBorderRadius = createStylesParam('tags-buttonTag-cornerRadius', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const buttonTagVerticalPadding = createStylesParam('tags-buttonTag-verticalPadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 6,
});
export const buttonTagHorizontalPadding = createStylesParam('tags-buttonTag-horizontalPadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});
export const buttonTagVerticalSpacing = createStylesParam('tags-buttonTag-verticalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 8,
});
export const buttonTagHorizontalSpacing = createStylesParam('tags-buttonTag-horizontalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 8,
});
// #endregion
// #region Design - Tags - Text
export const textTagAlignment = createStylesParam('tags-textTag-alignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => TagAlignment.Left,
});
export const textTagVerticalSpacing = createStylesParam('tags-textTag-verticalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});
export const textTagHorizontalSpacing = createStylesParam('tags-textTag-horizontalSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});
export const textTagTextFont = createStylesParam('tags-textTag-textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-S', { size: 14 }),
});
export const textTagTextColor = createStylesParam('tags-textTag-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});
export const textTagTextHoverColor = createStylesParam('tags-textTag-textHoverColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

// #endregion

// #region Design - Background and buttons
export const postBorderColor = createStylesParam('post-cardBorderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});
export const postBorderWidth = createStylesParam('post-cardBorderWidth', {
  type: StyleParamType.Number,
  inheritDesktop: false,
  getDefaultValue: ({ isMobile }) => (isMobile ? 0 : 1),
});
export const postBackgroundColor = createStylesParam('post-cardBackgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
});
export const postAreaBackgroundColor = createStylesParam('post-appBackgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
});
export const postButtonTextColor = createStylesParam('button-textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
});
export const postButtonBackgroundColor = createStylesParam('button-color', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

// #endregion
