import { useExperiments, useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';

type BlogMenuSettings = {
  showBlogMenu: boolean;
  showCategoryLabels: boolean;
  showSearch: boolean;
  showLoginButton: boolean;
};

const useBlogMenuSettings = (): BlogMenuSettings => {
  const { experiments } = useExperiments();
  const styles = useStyles();
  const { isEditorX: isStudio } = useEnvironment();

  const isPostPage = useSelector(getIsPostPageBundle);

  const showBlogMenu = useSelector((state) =>
    isPostPage && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
      ? isStudio
        ? styles.get(stylesParams.showMenuCategoryLabels)
        : styles.get(stylesParams.showMenu)
      : getIsBlogMenuEnabled(state),
  );

  const showCategoryLabels = useSelector((state) =>
    isPostPage && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
      ? styles.get(stylesParams.showMenuCategoryLabels)
      : getIsBlogMenuCategoryLabelsEnabled(state),
  );

  const showSearch = useSelector((state) =>
    isPostPage && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
      ? styles.get(stylesParams.showMenuSearch)
      : getIsBlogMenuSearchEnabled(state),
  );

  const showLoginButton = useSelector((state) =>
    isPostPage && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
      ? styles.get(stylesParams.showMenuLoginButton)
      : getIsBlogMenuLoginButtonEnabled(state),
  );

  return {
    showBlogMenu,
    showCategoryLabels,
    showSearch,
    showLoginButton,
  };
};

export default useBlogMenuSettings;
