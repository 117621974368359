import { createStylesParams, StyleParamType, type IStyleParam } from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { TagLayout } from '@wix/communities-blog-client-common';
import {
  getFlexAlignmentValue,
  getFontCssValue,
  getTextAlignmentValue,
  resolveDefaultStyleParamValue,
  type GetIStylesParams,
  type StyleParamsValues,
} from '@app/common/style-params';
import * as LEGACYSTYLEPARAMS from './legacyStylesParams';

const STYLE_PARAMS = {
  // #region Display - Checkboxes

  showAuthorName: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorName,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorNameMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showAuthorPicture: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorPicture,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorPictureMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostPublishDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostPublishDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostPublishDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showReadingTime: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showReadingTime,
        mobileParamName: LEGACYSTYLEPARAMS.showReadingTimeMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostTitle,
        mobileParamName: LEGACYSTYLEPARAMS.showPostTitleMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostUpdatedDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostUpdatedDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostUpdatedDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCategoryLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCategoryLabel,
        mobileParamName: LEGACYSTYLEPARAMS.showCategoryLabelMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showTags: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showTags,
        mobileParamName: LEGACYSTYLEPARAMS.showTagsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMoreOptionsMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMoreOptionsMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMoreOptionsMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostRating: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostRating,
        mobileParamName: LEGACYSTYLEPARAMS.showPostRatingMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCommentCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCommentCount,
        mobileParamName: LEGACYSTYLEPARAMS.showCommentCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showViewCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showViewCount,
        mobileParamName: LEGACYSTYLEPARAMS.showViewCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showLikeCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showLikeCount,
        mobileParamName: LEGACYSTYLEPARAMS.showLikeCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  // #endregion

  // #region Display - Menu

  /** Classic only */
  showMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  /** Classic only */
  showMenuLoginButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuLoginButton,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuLoginButtonMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  /** Should behave on Studio same as showMenu (sets menu visibility) */
  showMenuCategoryLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuCategoryLabels,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuCategoryLabelsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  /** Classic only */
  showMenuSearch: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuSearch,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuSearchMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  // #endregion

  // #region Display - Share
  showShare: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShare,
        mobileParamName: LEGACYSTYLEPARAMS.showShareMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconFacebook: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconFacebook,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconFacebookMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconX: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconX,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconXMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconLinkedIn: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconLinkedIn,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconLinkedInMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconLink: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconLink,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconLinkMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconPrint: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showShareIconPrint),
  },
  showSharePluginPinterest: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showSharePluginPinterest),
  },
  showSharePluginX: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showSharePluginX),
  },
  // #endregion

  // #region Display - Related posts
  showRelatedPosts: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPosts,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsLabel,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsLabelMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsLabelType: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsLabelType,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsLabelTypeMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsSeeAllLink: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsSeeAllLink,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsSeeAllLinkMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  // #endregion

  // #region Design - Navigation
  navigationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextFont, args),
  },
  navigationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) => {
      if (args.isMobile) {
        // Different parameter due to legacy mapping:
        // Mobile was mapped to "Text style and color > Paragraph / Text color"
        return getStyleParamValue(LEGACYSTYLEPARAMS.textParagraphColor, args);
      }

      return getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextColor, args);
    },
  },
  navigationTextActiveColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextActiveColor, args),
  },
  navigationBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) => {
      if (args.isMobile) {
        // Different parameter due to legacy mapping:
        // Mobile was mapped to "Background and buttons > Post background"
        return getStyleParamValue(LEGACYSTYLEPARAMS.postBackgroundColor, args);
      }

      return getStyleParamValue(LEGACYSTYLEPARAMS.navigationBackgroundColor, args);
    },
  },
  // #endregion
  // #region Design - Text style and color
  textParagraphFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textParagraphFont, args),
  },
  textParagraphColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textParagraphColor, args),
  },
  textQuoteFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textQuoteFont, args),
  },
  textQuoteColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textQuoteColor, args),
  },
  textLinksAndHashtagsColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textLinksAndHashtagsColor, args),
  },
  textH1Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH1Font, args),
  },
  textH1Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH1Color, args),
  },
  textH2Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH2Font, args),
  },
  textH2Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH2Color, args),
  },
  textH3Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH3Font, args),
  },
  textH3Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH3Color, args),
  },
  textH4Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH4Font, args),
  },
  textH4Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH4Color, args),
  },
  textH5Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH5Font, args),
  },
  textH5Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH5Color, args),
  },
  textH6Font: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH6Font, args),
  },
  textH6Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH6Color, args),
  },
  // #endregion
  // #region Design - Ratings
  ratingsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsTextFont, args),
  },
  ratingsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsTextColor, args),
  },
  ratingsStarFilledColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsStarFilledColor, args),
  },
  ratingsStarEmptyColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsStarEmptyColor, args),
  },
  // #endregion
  // #region Design - Tags
  showTagsLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showTagsLabel, args),
  },
  tagLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.tagLayout, args),
  },
  // #endregion
  // #region Design - Tags - Button
  buttonTagAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagAlignment, args),
  },
  buttonTagTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextFont, args),
  },
  buttonTagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextColor, args),
  },
  buttonTagTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextHoverColor, args),
  },
  buttonTagBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBackgroundColor, args),
  },
  buttonTagBackgroundHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBackgroundHoverColor, args),
  },
  buttonTagBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderColor, args),
  },
  buttonTagBorderHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderHoverColor, args),
  },
  buttonTagBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderWidth, args),
  },
  buttonTagBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderRadius, args),
  },
  buttonTagVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagVerticalPadding, args),
  },
  buttonTagHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagHorizontalPadding, args),
  },
  buttonTagVerticalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagVerticalSpacing, args),
  },
  buttonTagHorizontalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagHorizontalSpacing, args),
  },
  // #endregion
  // #region Design - Tags - Text
  textTagAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagAlignment, args),
  },
  textTagTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextFont, args),
  },
  textTagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextColor, args),
  },
  textTagTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextHoverColor, args),
  },
  textTagVerticalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagVerticalSpacing, args),
  },
  textTagHorizontalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagHorizontalSpacing, args),
  },
  // #endregion
  // #region Design - Background and buttons
  postBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBorderColor, args),
  },
  postBorderWidth: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBorderWidth, args),
  },
  postBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBackgroundColor, args),
  },
  postAreaBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postAreaBackgroundColor, args),
  },
  postButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postButtonTextColor, args),
  },
  postButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue, ...args }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postButtonBackgroundColor, args),
  },
  // #endregion
} as const satisfies {
  [key: string]: Omit<IStyleParam, 'name'>;
};

type IStylesParams = GetIStylesParams<typeof STYLE_PARAMS>;

export type PostPageStyleParams = StyleParamsValues<IStylesParams>;

export const customCssVars: CustomCssVarsFn<StyleParamsValues<IStylesParams>> = ({
  isRTL,
  styleParams: { numbers, fonts, colors, booleans },
}) => {
  const hasVisibleShareIcons =
    booleans.showShare &&
    (booleans.showShareIconFacebook ||
      booleans.showShareIconX ||
      booleans.showShareIconLinkedIn ||
      booleans.showShareIconLink ||
      booleans.showShareIconPrint);
  const hasCounters = booleans.showViewCount || booleans.showCommentCount || booleans.showLikeCount;
  const hasFooterActions = hasVisibleShareIcons || hasCounters;
  return {
    postSectionMetadataDisplay:
      booleans.showAuthorName ||
      booleans.showAuthorPicture ||
      booleans.showPostPublishDate ||
      booleans.showReadingTime
        ? 'grid'
        : 'none',
    postAuthorNameDisplay: booleans.showAuthorName ? 'contents' : 'none',
    postAuthorPictureDisplay:
      booleans.showAuthorName && booleans.showAuthorPicture ? 'inline-block' : 'none',
    postPublishDateDisplay: booleans.showPostPublishDate ? 'block' : 'none',
    postPublishDateSeparatorDisplay: booleans.showAuthorName ? 'inline' : 'none',
    postReadingTimeDisplay: booleans.showReadingTime ? 'block' : 'none',
    postReadingTimeSeparatorDisplay:
      booleans.showAuthorName || booleans.showPostPublishDate ? 'inline' : 'none',
    postTitleDisplay: booleans.showPostTitle ? 'block' : 'none',
    postUpdatedDateDisplay: booleans.showPostUpdatedDate ? 'block' : 'none',
    postRatingDisplay: booleans.showPostRating ? 'flex' : 'none',
    postMoreActionsDisplay: booleans.showMoreOptionsMenu ? 'block' : 'none',

    postFooterDisplay:
      hasFooterActions || booleans.showCategoryLabel || booleans.showTags ? 'flex' : 'none',
    postFooterActionsDisplay: hasFooterActions ? 'block' : 'none',
    postSectionCountersDisplay: hasCounters ? 'flex' : 'none',
    postViewCountDisplay: booleans.showViewCount ? 'flex' : 'none',
    postCommentCountDisplay: booleans.showCommentCount ? 'flex' : 'none',
    postLikeCountDisplay: booleans.showLikeCount ? 'flex' : 'none',

    postSectionShareDisplay: hasVisibleShareIcons ? 'flex' : 'none',
    postShareIconFacebookDisplay: booleans.showShareIconFacebook ? 'block' : 'none',
    postShareIconXDisplay: booleans.showShareIconX ? 'block' : 'none',
    postShareIconLinkedInDisplay: booleans.showShareIconLinkedIn ? 'block' : 'none',
    postShareIconLinkDisplay: booleans.showShareIconLink ? 'block' : 'none',
    postShareIconPrintDisplay: booleans.showShareIconPrint ? 'block' : 'none',

    relatedPostsDisplay: booleans.showRelatedPosts ? 'block' : 'none',
    relatedPostsItemFooterOffset: hasCounters ? '45px' : '0px',
    relatedPostsSeeAllDisplay: booleans.showRelatedPostsSeeAllLink ? 'inline' : 'none',

    postCategoriesDisplay: booleans.showCategoryLabel ? 'flex' : 'none',
    tagDisplay: booleans.showTags ? 'flex' : 'none',
    tagLabelDisplay: booleans.showTagsLabel ? 'block' : 'none',
    tagTextFont:
      numbers.tagLayout === TagLayout.Button
        ? getFontCssValue(fonts.buttonTagTextFont, 'font')
        : getFontCssValue(fonts.textTagTextFont, 'font'),
    tagTextDecoration:
      numbers.tagLayout === TagLayout.Button
        ? getFontCssValue(fonts.buttonTagTextFont, 'textDecoration')
        : getFontCssValue(fonts.textTagTextFont, 'textDecoration'),
    tagTextColor:
      numbers.tagLayout === TagLayout.Button ? colors.buttonTagTextColor : colors.textTagTextColor,
    tagTextHoverColor:
      numbers.tagLayout === TagLayout.Button
        ? colors.buttonTagTextHoverColor
        : colors.textTagTextHoverColor,
    tagBorderWidth: numbers.tagLayout === TagLayout.Button ? numbers.buttonTagBorderWidth : 0,
    tagBorderStyle: numbers.tagLayout === TagLayout.Button ? 'solid' : 'none',
    tagBorderColor:
      numbers.tagLayout === TagLayout.Button ? colors.buttonTagBorderColor : 'transparent',
    tagBorderHoverColor:
      numbers.tagLayout === TagLayout.Button ? colors.buttonTagBorderHoverColor : 'transparent',
    tagBorderRadius: numbers.tagLayout === TagLayout.Button ? numbers.buttonTagBorderRadius : 0,
    tagBackgroundColor:
      numbers.tagLayout === TagLayout.Button ? colors.buttonTagBackgroundColor : 'transparent',
    tagBackgroundHoverColor:
      numbers.tagLayout === TagLayout.Button ? colors.buttonTagBackgroundHoverColor : 'transparent',
    tagFlexAlignment: getFlexAlignmentValue(
      numbers.tagLayout === TagLayout.Button
        ? numbers.buttonTagAlignment
        : numbers.textTagAlignment,
      isRTL,
    ),
    tagTextAlignment: getTextAlignmentValue(
      numbers.tagLayout === TagLayout.Button
        ? numbers.buttonTagAlignment
        : numbers.textTagAlignment,
      isRTL,
    ),
    tagVerticalSpacing:
      numbers.tagLayout === TagLayout.Button
        ? numbers.buttonTagVerticalSpacing
        : numbers.textTagVerticalSpacing,
    tagHorizontalSpacing:
      numbers.tagLayout === TagLayout.Button
        ? numbers.buttonTagHorizontalSpacing
        : numbers.textTagHorizontalSpacing,
    tagVerticalPadding:
      numbers.tagLayout === TagLayout.Button ? numbers.buttonTagVerticalPadding : 0,
    tagHorizontalPadding:
      numbers.tagLayout === TagLayout.Button ? numbers.buttonTagHorizontalPadding : 0,
  };
};

export default createStylesParams<IStylesParams>(STYLE_PARAMS);
